import React, {
  useEffect,
  useMemo,
  useCallback,
  SyntheticEvent,
  CSSProperties,
  useRef,
  useImperativeHandle,
  Ref,
  forwardRef,
  useState,
} from "react";
import {
  Checkbox,
  Form,
  FormField,
  FormGroup,
  Icon,
  Label,
  Popup,
  Input,
  Select,
  Button,
  Dropdown,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

// UX
import CardDrugOrderQueueUX from "../TPD/CardDrugOrderQueueUX";
import CardPatientSearchBox from "./CardPatientSearchBox";
import ButtonEditRemark from "./ButtonEditRemark";
import ButtonLockDrugOrder from "./ButtonLockDrugOrder"

// Common
import DropdownOptions from "../../../appcon/common/DropdownOptions";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModInfo from "react-lib/apps/common/ModInfo";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { getDrugOrderStatusType } from "./sequence/SetDrugOrderQueue";
import { DAILY_TYPES, DRUG_ORDER_TYPE, getDrugTypeName } from "./TPDInterface";
import { useIntl } from "react-intl";

import CONFIG from "config/config"


// Const
const CheckboxStyle = {
  display: "grid",
  justifyContent: "center",
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  alignItems: "center",
} as CSSProperties;

export const approveOption = [
  { key: "ALL", text: "ALL", value: "ALL" },
  { key: "APPROVED", text: "APPROVED", value: "APPROVED" },
  { key: "NEED_APPROVE", text: "NEED APPROVE", value: "NEED_APPROVE" },
];

const styles = {
  not_found: {
    display: "grid",
    textAlign: "center",
    whiteSpace: "pre-line",
    lineHeight: "1.35",
    margin: "0 -0.75rem",
  } as CSSProperties,
};

const IMAGES = {
  clipboard_blue: "/static/images/drugOrder/clipboard-with-clock-blue.png",
  clipboard_grey: "/static/images/drugOrder/clipboard-with-clock-grey.png",
};

const BUTTON_ACTIONS = {
  barcode: "barcode",
  search: "search",
};

const CARD_DRUG_ORDER_QUEUE = "CardDrugOrderQueue";

const ACTION_SEARCH = `${CARD_DRUG_ORDER_QUEUE}_${BUTTON_ACTIONS.search}`;
const ACTION_BARCODE = `${CARD_DRUG_ORDER_QUEUE}_${BUTTON_ACTIONS.barcode}`;

const CardDrugOrderQueue = (props: any, ref: Ref<any>) => {
  const intl = useIntl();
  const [modDrugPendingRemark, setModDrugPendingRemark] = useState<Record<string, any> | null>(
    null
  );
  const [barcodeScan, setBarcodeScan] = useState<string>("")

  const isMounted = useRef(false);
  const clickCountRef = useRef(0);
  const clickTimeoutRef = useRef<any>(null);

  const history = useHistory();

  // const VERBAL_ORDER_MAPPED: { [key: string]: string } = {
  //   DRUG_ORDER_ONE_DOSE__OPD: "ONE_DOSE_OPD",
  //   DRUG_ORDER_HOME_OPD: "HOME_OPD",
  //   DRUG_ORDER_HOME_IPD: "HOME_IPD",
  //   DRUG_ORDER_ONE_DOSE__IPD: "ONE_DOSE_IPD",
  //   DRUG_ORDER_ONE_DAY: "ONE_DAY_IPD",
  // };
  useImperativeHandle(ref, () => ({
    refresh: () => {
      handleSearch(true);
    },
  }));

  useEffect(() => {
    if (props.selectedDivision?.id) {
      props.runSequence?.({
        sequence: "SetDrugOrderQueue",
        card: CARD_DRUG_ORDER_QUEUE,
        isClearEncounter: true,
        restart: true,
      });
    }
  }, [props.selectedDivision?.id]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
      props.runSequence?.({ sequence: "SetDrugOrderQueue", clear: true });
    };
  }, []);

  // useEffect(() => {
  //   if (
  //     props.SetDrugOrderQueueSequence?.sequenceIndex === "preparedMasterData"
  //   ) {
  //     props.runSequence?.({
  //       sequence: "SetDrugOrderQueue",
  //       card: CARD_DRUG_ORDER_QUEUE,
  //     });
  //   }
  // }, [props.SetDrugOrderQueueSequence?.sequenceIndex]);

  const handleChangeCheckbox = useCallback(
    (e: SyntheticEvent, v: any) => {
      console.log("handleChangeCheckbox v: ", v);
      handlePreventDefault(e);

      let checked = [...(props.SetDrugOrderQueueSequence?.checkedList || [])];
      if (v.checked) {
        checked.push(v.value);
      } else {
        checked = checked.filter((id) => id !== v.value);
      }

      props.setProp("SetDrugOrderQueueSequence.checkedList", checked);
    },
    [props.SetDrugOrderQueueSequence?.checkedList]
  );

  const handlePreventDefault = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleLockDrugOrderSuccess = useCallback(() => {
    if (props.hidePanel) {
      props.onEvent({
        message: "GetDrugOrderQueue",
        params: { patient: props.selectedEncounter?.patient_id },
      });
    } else {
      handleSearch();
    }
  }, [props.hidePanel, props.selectedEncounter]);

  const handleFilterPatient = async (data: Record<string, any>) => {
    await props.setProp("SetDrugOrderQueueSequence.isFilterByPatient", true);

    await handleOnEnterPatientSearch(Number(data.patient_id), data.hn);
    // handleSearch();
  };

  const printableList = useMemo(() => {
    return (props.drugOrderQueue || []).filter((item: any) => item.type !== "CONTINUE_PLAN");
  }, [props.drugOrderQueue]);

  const drugOrderQueue = useMemo(() => {
    const orderQueue = Number.isInteger(props.selectedEncounter?.patient_id)
      ? props.drugOrderQueue.filter(
          (item: any) => item.patient_id === props.selectedEncounter?.patient_id.toString()
        )
      : props.drugOrderQueue;

    return (
      orderQueue?.map((drug: any, index) => {
        const isVerbalOrder = drug?.order_type_name !== "DIRECT" && !drug?.order_approve_time;
        const isCosigned = drug?.order_type_name !== "DIRECT" && drug?.order_approve_time;

        return {
          ...drug,
          code: (
            <div>
              <div>{drug.code}</div>
              {isVerbalOrder || isCosigned ? (
                <Popup
                  trigger={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {!["CONTINUE_PLAN", "CONTINUE"].includes(drug.type) && (
                        <ButtonLockDrugOrder
                          onEvent={props.onEvent}
                          setProp={props.setProp}
                          status={drug.locker_general_status}
                          orderId={drug.id}
                          errorMessage={props.errorMessage}
                          buttonLoadCheck={props.buttonLoadCheck}
                          onSuccess={handleLockDrugOrderSuccess}
                        />
                      )}
                      <Label
                        color={isVerbalOrder ? "orange" : "green"}
                        size="tiny"
                        style={{ marginLeft: "0.2rem" }}
                      >
                        {isVerbalOrder ? "รคส." : "รคส. (co-signed)"}
                      </Label>
                    </div>
                  }
                  content={
                    <>
                      <strong>ผู้บันทึกรับคำสั่ง</strong>
                      <br />
                      {drug?.order_receive_by_name}
                    </>
                  }
                ></Popup>
              ) : (
                <div style={{ justifyContent: "center", display: "flex", width: "100%" }}>
                  {!["CONTINUE_PLAN", "CONTINUE"].includes(drug.type) && (
                    <ButtonLockDrugOrder
                      onEvent={props.onEvent}
                      setProp={props.setProp}
                      status={drug.locker_general_status}
                      orderId={drug.id}
                      errorMessage={props.errorMessage}
                      buttonLoadCheck={props.buttonLoadCheck}
                      onSuccess={handleLockDrugOrderSuccess}
                    />
                  )}
                </div>
              )}
            </div>
          ),
          checkbox: (
            <div style={CheckboxStyle} onClick={handlePreventDefault}>
              {drug.type !== "CONTINUE_PLAN" && (
                <Checkbox
                  checked={!!props.SetDrugOrderQueueSequence?.checkedList?.includes(drug.id)}
                  onChange={handleChangeCheckbox}
                  name={drug.id.toString()}
                  value={drug.id}
                />
              )}
            </div>
          ),
          requested: (
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr 20px", height: "fit-content" }}
            >
              <div>{drug.requested ? formatDatetime(drug.requested) : ""}</div>
              <div style={{ margin: "auto 0 0" }}>
                <ButtonEditRemark
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  id={drug.id}
                  value={drug.pending_remark}
                  buttonLoadCheck={props.buttonLoadCheck}
                  errorMessage={props.errorMessage}
                  trigger={
                    <img
                      src={drug.pending_remark ? IMAGES.clipboard_blue : IMAGES.clipboard_grey}
                      alt="Clipboard and clock icon"
                      style={{ width: "20px", cursor: "pointer", marginBottom: "-0.25rem" }}
                    />
                  }
                />
              </div>
            </div>
          ),
          encounter_type: (
            <div style={{ display: "flex" }}>
              <div>{drug.encounter_type}</div>
              {drug.is_telemed && (
                <Label circular color="yellow" style={{ height: "1.8rem", width: "1.8rem" }}>
                  <Icon name="phone" style={{ transform: "rotate(90deg)" }} />
                </Label>
              )}
            </div>
          ),
          order_by_name: (
            <div style={{ display: "flex" }}>
              <div style={{ width: "80%" }}>{drug.order_by_name}</div>
              <div style={{ width: "20%" }}>
                {drug.student && drug.need_approve ? (
                  <img src="/static/images/drugOrder/need_approve.png" />
                ) : (
                  drug.student &&
                  !drug.need_approve && (
                    <Popup
                      content={
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: "#898383",
                              fontSize: "12px",
                            }}
                          >
                            {intl.formatMessage({ id: "ตรวจสอบโดย" })}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {drug.approved_by_name}
                          </div>
                        </div>
                      }
                      on="hover"
                      pinned
                      position="bottom center"
                      trigger={<img src="/static/images/drugOrder/approve_check_out_line.png" />}
                    />
                  )
                )}
              </div>
            </div>
          ),
          patientName: props.hidePanel ? (
            drug.patient
          ) : (
            <span
              className="clickable-text"
              onClick={(e) => {
                e.stopPropagation();

                handleFilterPatient(drug);
              }}
            >
              {drug.patient}
            </span>
          ),
          prescription: <div> {drug?.order_for_date} </div>,
          status: (
            <div style={{ textAlign: "center" }}>
              <div>{drug.status}</div>
              <div style={{ color: "rgb(39, 174, 96)", fontWeight: "bold" }}>
                {drug.order_payment_status === "PAID" ? "PAID" : ""}
              </div>
            </div>
          ),
          type: getDrugTypeName(drug.type),
        }; //moment(drug.requested).format("DD-MM-YYYY [[]HH:mm[]]"): ""}
      }) || []
    );
  }, [
    props.drugOrderQueue,
    props.selectedEncounter,
    props.SetDrugOrderQueueSequence?.checkedList,
    props.hidePanel,
    props.buttonLoadCheck,
    props.errorMessage,
    handleLockDrugOrderSuccess,
  ]);

  const handleCheckAll = useCallback(
    (e: any, v: any) => {
      if (v.checked) {
        props.setProp(
          "SetDrugOrderQueueSequence.checkedList",
          printableList.map((item: any) => item.id)
        );
      } else {
        props.setProp("SetDrugOrderQueueSequence.checkedList", []);
      }
    },
    [printableList]
  );

  const drugOrderQueueHeaders = useMemo(() => {
    const headers: any[] = [
      "Order No.",
      "Status",
      "Encounter Type",
      "Order Type",
      "HN",
      "Patient name",
      "Order by",
      "Department",
      "Created date/time",
      "Prescription date",
      "Shipper",
      "Tracking No.",
    ];

    if (!props.hidePanel) {
      headers.unshift(
        <Checkbox
          checked={
            printableList.length === props.SetDrugOrderQueueSequence?.checkedList?.length &&
            !!printableList.length
          }
          onClick={handleCheckAll}
        />
      );
    }

    return headers;
  }, [drugOrderQueue, props.SetDrugOrderQueueSequence?.checkedList]);

  const selectedSort = useMemo(
    () => props.SetDrugOrderQueueSequence?.selectedSort || "desc",
    [props.SetDrugOrderQueueSequence?.selectedSort]
  );

  const sortOptions = useMemo(
    () => [
      {
        key: "desc",
        text: "Newest first",
        value: "desc",
        content: <SortOptionContent selectedSort={selectedSort} text="Newest first" value="desc" />,
      },
      {
        key: "asc",
        text: "Oldest first",
        value: "asc",
        content: <SortOptionContent selectedSort={selectedSort} text="Oldest first" value="asc" />,
      },
    ],
    [selectedSort]
  );

  const drugOrderType = useMemo(() => {
    let orderTypes: Record<string, any>[] = props.masterOptions?.drugOrderType || [];

    const isIncludeDaily = DAILY_TYPES.some((text) => orderTypes.find((option) => option.text === text));
    const isDaily = !CONFIG.V3_HIDDEN_TAB_LIST.includes(DRUG_ORDER_TYPE.DAILY);

    if (isIncludeDaily && isDaily) {
      const matchedValues = DAILY_TYPES.map((text) => {
        const foundType = orderTypes.find((option) => option.text === text);

        return foundType?.value as number;
      });
      const statIndex = orderTypes.findIndex((option) => option.text === "STAT");

      orderTypes = orderTypes.filter((option) => !DAILY_TYPES.includes(option.text));

      orderTypes.splice(statIndex, 0, {
        key: "DAILY",
        text: "DAILY",
        value: matchedValues.toString(),
      });
    }

    return orderTypes;
  }, [props.masterOptions?.drugOrderType]);

  const handleOnEnterPatientSearch = async (id: number | null, hn: string) => {
    if (isMounted.current) {
      await props.setProp("SetDrugOrderQueueSequence.selectedPatientSearch", id);
      await props.setProp("SetDrugOrderQueueSequence.selectedPatientSearchHN", hn);

      if (id) {
        handleSearch();
      }
    }
  };

  const handleClickRow = (rowInfo: any) => {
    // console.log("CardDrugOrderQueue Select !!! ");
    props.onEvent({
      message: "SelectVerbalDrugOrderWorking",
      params: { verbalDrugOrder: rowInfo?.original || null },
    });
    // console.log("CardDrugOrderQueue SelectVerbalDrugOrderWorking ", rowInfo?.original);
    // props.setProp("goToDoctorDrugOrderCode", rowInfo?.original.drug_order_code)
    props?.layout === 0 && props?.forward?.();

    // ไป Med Order ทั้งหมด ทั้ง IPD, และ OPD แต่หน้าตา ไม่เหมือนกัน

    // if (
    //   VERBAL_ORDER_MAPPED[rowInfo?.original?.order_specific_type].includes(
    //     "IPD"
    //   )
    // ) {
    //   console.log("CardDrugOrderQueue selectedRecordViewIndex ", 11);
    //   props.setProp("selectedRecordViewIndex", 11);
    // } else {
    //   console.log("CardDrugOrderQueue selectedRecordViewIndex ", 10);
    //   props.setProp("selectedRecordViewIndex", 10);
    // }
  };

  // Table
  const handleVerbalRowProps = (state: any, rowInfo: any, column: any, instance: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === props.selectedDrugOrderWorking?.pk
            ? "#cccccc"
            : "white",
      },
      onClick: () => {
        handleClickRow(rowInfo);
      },
      onDoubleClick: () => {
        handleClickRow(rowInfo);
      },
    };
  };

  const handleSelectDrugOrder = (data: any, isNotGetEncounter?: boolean) => {
    console.log("handleSelectDrugOrder data: ", data);
    props.onEvent({
      message: "SelectDrugOrderWorking",
      params: {
        drugOrder: data || null,
        history,
        isNotGetEncounter: isNotGetEncounter ?? props?.layout !== 0,
      },
    });

    if (props.layout === 0) {
      props?.forward?.();

      props.setProp("drugOrderQueue", []);
    }

    props.setProp("selectedRecordViewIndex", 0);
  };

  const handleRowProps = (state: any, rowInfo: any, column: any, instance: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === props.selectedDrugOrderWorking?.pk
            ? "#cccccc"
            : "white",
        color:
          rowInfo?.original?.type === "OPERATING"
            ? "#810CB6"
            : rowInfo?.original?.type === "STAT"
            ? "#db2828"
            : "",
        fontWeight: rowInfo?.original?.type === "STAT" ? "bold" : "",
      },
      onClick: () => {
        clickCountRef.current += 1;

        clickTimeoutRef.current = setTimeout(() => {
          if (clickCountRef.current === 1) {
            handleSelectDrugOrder(rowInfo?.original);
          }
          clickCountRef.current = 0;
        }, 300);
      },
      onDoubleClick: () => {
        clearTimeout(clickTimeoutRef.current);

        handleSelectDrugOrder(rowInfo?.original);

        clickTimeoutRef.current = 0;
      },
      // onClick: () => {
      //   console.log('onClick props?.layout: ', props?.layout);
      //   props.onEvent({
      //     message: "SelectDrugOrderWorking",
      //     params: {
      //       drugOrder: rowInfo?.original || null,
      //       history,
      //     },
      //   });
      //   props?.layout === 0 && props?.forward?.();
      //   props.setProp("selectedRecordViewIndex", 0);
      // },
      // onDoubleClick: () => {
      //   console.log('onDoubleClick props?.layout: ', props?.layout);
      //   if (props?.layout === 1) {
      //     props.onEvent({
      //       message: "SelectDrugOrderWorking",
      //       params: { drugOrder: rowInfo?.original || null, history },
      //     });
      //     props?.forward?.();
      //     props.setProp("selectedRecordViewIndex", 0);
      //   }
      // },
    };
  };

  const changeSetDrugOrder = (key: string) => (e: any, v: any) => {
    props.setProp(`SetDrugOrderQueueSequence.${key}`, v.value);
  };

  const changeSetDrugOrderCheck =
    (key: string) =>
    (e: any, { checked }: any) => {
      props.setProp(`SetDrugOrderQueueSequence.${key}`, checked);
    };

  const handleResetFilter =
    (key: "selectedDrugOrderStatus" | "selectedDrugOrderType") => (e: any) => {
      const data = getDrugOrderStatusType(props.masterOptions);

      changeSetDrugOrder(key)(null, { value: data[key] });
    };

  const handleSearch = (isRefresh = false) => {
    props.runSequence?.({
      sequence: "SetDrugOrderQueue",
      action: "search",
      card: CARD_DRUG_ORDER_QUEUE,
      isRefresh,
    });
  };

  const handleChangeSort = async (_event: any, data: any) => {
    await props.setProp(`SetDrugOrderQueueSequence.${data.name}`, data.value);
    handleSearch();
  };

  const handleClearFilterPatient = async () => {
    await handleOnEnterPatientSearch(null, "");

    await props.setProp("SetDrugOrderQueueSequence.isFilterByPatient", false);

    handleSearch();
  };

  const handleCloseDrugPendingRemark = () => {
    setModDrugPendingRemark(null);
  };

  const handleKeyDownBarcode = (e: KeyboardEvent) => {
    if (e.key === "Enter" && barcodeScan) {
      props.onEvent({
        message: "HandleGetDrugOrderByBarcode",
        params: {
          barcode: barcodeScan,
          btnAction: ACTION_BARCODE,
          onSuccess: (data: any) => {
            setBarcodeScan("");

            handleSelectDrugOrder(data, props.selectedEncounter?.id === data.encounter);
          },
        },
      });
    }
  };

  const handleCloseModBarcode = ()=> {
    props.setProp(`errorMessage.${ACTION_BARCODE}`, null);
  }

  console.log("CardDrugOrderQueue props: ", props);
  // console.log("CardDrugOrderQueue props.SetDrugOrderQueueSequence?.selectedDrugOrderStatus: ", props.SetDrugOrderQueueSequence?.selectedDrugOrderStatus);
  // console.log("CardDrugOrderQueue props.SetDrugOrderQueueSequence?.selectedDrugOrderType: ", props.SetDrugOrderQueueSequence?.selectedDrugOrderType);

  return (
    <>
      <CardDrugOrderQueueUX
        // data
        // hidePanel={props.hidePanel || false}
        hidePanel={props.hidePanel || false}
        isVerbal={props.isVerbal || false}
        height={props.height}
        drugOrderQueueHeaders={drugOrderQueueHeaders}
        drugOrderQueue={drugOrderQueue}
        verbalOrderQueue={props.verbalDrugOrderQueue}
        selectedDrugOrderWorking={props.selectedDrugOrderWorking}
        selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
        searching={props.SetDrugOrderQueueSequence?.searching || false}
        selectedDivision={props.SetDrugOrderQueueSequence?.selectedDivision || ""}
        selectedDrugOrderType={props.SetDrugOrderQueueSequence?.selectedDrugOrderType || []}
        selectedShipper={props.SetDrugOrderQueueSequence?.selectedShipper || ""}
        selectedDrugOrderStatus={props.SetDrugOrderQueueSequence?.selectedDrugOrderStatus || []}
        selectedLocation={props.SetDrugOrderQueueSequence?.selectedLocation || ""}
        selectedApprove={props.SetDrugOrderQueueSequence?.selectedApprove || "ALL"}
        checkedOPD={props.SetDrugOrderQueueSequence?.checkedOPD || false}
        checkedIPD={props.SetDrugOrderQueueSequence?.checkedIPD || false}
        checkedContinue={props.SetDrugOrderQueueSequence?.checkedContinue || false}
        // options
        divisionOptions={props.masterOptions?.division || []}
        drugOrderType={props.masterOptions?.drugOrderType || []}
        orderStatusOptions={props.masterOptions?.drugOrderStatus || []}
        shipperOptions={props.masterOptions?.shippingCompany || []}
        divisionTypeDrugOptions={props.masterOptions?.divisionTypeDrug || []}
        approveOption={approveOption}
        // sortOptions={sortOptions}
        // selectedSort={selectedSort}
        hideSort={false} //CONFIG.FEATURE_DISABLED_UP_TO_VERS["202401"]}
        // function
        changeSetDrugOrder={changeSetDrugOrder}
        changeSetDrugOrderCheck={changeSetDrugOrderCheck}
        onSearch={() => {
          props.runSequence?.({ sequence: "SetDrugOrderQueue", action: "search" });
        }}
        // onChangeSort={handleChangeSort}
        // table
        rowProps={handleRowProps}
        // verbal table
        verbalRowProps={handleVerbalRowProps}
        onResetFilter={handleResetFilter}
        // patient search
        patientSearch={
          <CardPatientSearchBox
            // controller
            controller={props.controller}
            // data
            value={props.SetDrugOrderQueueSequence?.selectedPatientSearchHN || ""}
            disabled={props.SetDrugOrderQueueSequence?.isFilterByPatient}
            // callback
            onEnterPatientSearch={handleOnEnterPatientSearch}
            languageUX={props.languageUX}
          />
        }
        clearFilterPatient={
          props.SetDrugOrderQueueSequence?.isFilterByPatient && (
            <Label
              style={{
                borderRadius: "500rem",
                padding: "0 7px 1px",
                height: "1.25rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "normal",
                cursor: "pointer",
              }}
              size="small"
              color="orange"
              onClick={handleClearFilterPatient}
            >
              Clear Filter
            </Label>
          )
        }
        // Element
        StartDate={
          <DateTextBox
            value={props.SetDrugOrderQueueSequence?.selectedStartDate || ""}
            onChange={(date: string) =>
              changeSetDrugOrder("selectedStartDate")(null, { value: date })
            }
          />
        }
        EndDate={
          <DateTextBox
            value={props.SetDrugOrderQueueSequence?.selectedEndDate || ""}
            onChange={(date: string) =>
              changeSetDrugOrder("selectedEndDate")(null, { value: date })
            }
          />
        }
        OrderStatus={
          <DropdownOptions
            value={props.SetDrugOrderQueueSequence?.selectedDrugOrderStatus || []}
            multiple={true}
            search={true}
            searchBox={true}
            placeholder="เลือก Order Status"
            checked={true}
            options={props.masterOptions?.drugOrderStatus || []}
            onChange={changeSetDrugOrder("selectedDrugOrderStatus")}
            // fluid={true}
            // style={{ width: "100%" }}
          />
        }
        OrderType={
          <DropdownOptions
            value={props.SetDrugOrderQueueSequence?.selectedDrugOrderType || []}
            multiple={true}
            search={true}
            searchBox={true}
            placeholder="เลือก Order Type"
            checked={true}
            options={drugOrderType}
            onChange={changeSetDrugOrder("selectedDrugOrderType")}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              handleSearch();
            }}
            // data
            paramKey={ACTION_SEARCH}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SEARCH]}
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.search}
            size="medium"
            title="ค้นหา"
          />
        }
        refillPlaned={props.refillPlaned}
        showDrugOrderPendingList={props.showDrugOrderPendingList}
        barcodeScan={barcodeScan}
        barcodeLoading={!!props.buttonLoadCheck?.[ACTION_BARCODE]}
        onKeyDownBarcode={handleKeyDownBarcode}
        onChangeBarcode={(e: any, data: any) => {
          setBarcodeScan(data.value);
        }}
        searchVerbal={
          props.isVerbal ? (
            <Form>
              <FormGroup widths="equal">
                <FormField>
                  <label>{intl.formatMessage({ id: "ผู้ป่วย" })}</label>
                  <SearchBoxDropdown
                    onEvent={props.onEvent}
                    // config
                    type="HNFirstLast"
                    id="Verbal"
                    style={{ width: "100%" }}
                    fluid={true}
                    useWithKey={true}
                    icon="search"
                    limit={40}
                    inline={true}
                    placeholder={"HN, First name, Last name"}
                    noResultsMessage={
                      <div style={styles.not_found}>
                        <span>{intl.formatMessage({ id: "ไม่พบข้อมูล" })}</span>
                        <span>{intl.formatMessage({ id: "กรุณาทำการค้นหาใหม่อีกครั้ง" })}</span>
                      </div>
                    }
                    // Select
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    selectedItem={props.SetDrugOrderQueueSequence?.verbalFilter?.patient || null}
                    iconStyle={{ marginTop: "-1rem" }}
                    setSelectedItem={(value) => {
                      console.log("value: ", value);
                      props.setProp("SetDrugOrderQueueSequence.verbalFilter.patient", value);
                    }}
                  />
                </FormField>
                <FormField>
                  <label>{intl.formatMessage({ id: "แผนกที่สั่ง" })}</label>
                  <Dropdown
                    // onEvent={props.onEvent}
                    // config
                    clearable
                    fluid
                    selection
                    style={{ width: "100%" }}
                    // Select

                    onChange={(e, { value }) => {
                      props.setProp("SetDrugOrderQueueSequence.verbalFilter.division", value);
                    }}
                    value={props.SetDrugOrderQueueSequence?.verbalFilter?.division}
                    options={props.masterOptions?.division || []}
                    // options
                    // mapOptions={()=>{}}
                  />
                </FormField>
                <FormField>
                  <label>{intl.formatMessage({ id: "แพทย์ผู้สั่ง" })}</label>
                  <SearchBoxDropdown
                    onEvent={props.onEvent}
                    // config
                    type="Doctor"
                    id="Verbal"
                    style={{ width: "100%" }}
                    fluid={true}
                    useWithKey={true}
                    icon="search"
                    limit={40}
                    inline={true}
                    noResultsMessage={
                      <div style={styles.not_found}>
                        <span>{intl.formatMessage({ id: "ไม่พบข้อมูล" })}</span>
                        <span>{intl.formatMessage({ id: "กรุณาทำการค้นหาใหม่อีกครั้ง" })}</span>
                      </div>
                    }
                    // Select
                    searchedItemListWithKey={props.searchedItemListWithKey}
                    selectedItem={props.SetDrugOrderQueueSequence?.verbalFilter?.doctor || null}
                    iconStyle={{ marginTop: "-1rem" }}
                    setSelectedItem={(value) => {
                      console.log("value: ", value);
                      props.setProp("SetDrugOrderQueueSequence.verbalFilter.doctor", value);
                    }}
                  />
                </FormField>
                <FormField>
                  <label>{intl.formatMessage({ id: "วันที่สั่ง" })}</label>
                  <DateTextBox
                    value={props.SetDrugOrderQueueSequence?.verbalFilter?.startDate || ""}
                    onChange={(date: string) => {
                      console.log("date: ", date);
                      props.setProp("SetDrugOrderQueueSequence.verbalFilter.startDate", date);
                    }}
                  />
                </FormField>
                <FormField>
                  <label>{intl.formatMessage({ id: "ถึง" })}</label>
                  <DateTextBox
                    value={props.SetDrugOrderQueueSequence?.verbalFilter?.endDate || ""}
                    onChange={(date: string) => {
                      console.log("date: ", date);
                      props.setProp("SetDrugOrderQueueSequence.verbalFilter.endDate", date);
                    }}
                  />
                </FormField>
                <FormField style={{ alignSelf: "flex-end" }}>
                  <Button
                    color="blue"
                    onClick={() => {
                      props.onEvent({
                        message: "GetDrugOrderQueue",
                        params: { fetchDrugVerbalOnly: true },
                      });
                    }}
                  >
                    ค้นหา
                  </Button>
                </FormField>
              </FormGroup>
            </Form>
          ) : null
        }
        dropdownSort={
          <Dropdown
            defaultValue="desc"
            text="Sort by"
            name="selectedSort"
            onChange={handleChangeSort}
            options={sortOptions}
            selection={true}
            style={{ minWidth: "9rem" }}
            value={selectedSort}
          ></Dropdown>
        }
        countdownTimer={
          <CountdownTimer runSequence={props.runSequence} drugOrderQueue={props.drugOrderQueue} />
        }
        languageUX={props.languageUX}
      />

      <ModInfo
        open={props.errorMessage?.[ACTION_BARCODE]}
        type="error"
        onClose={handleCloseModBarcode}
        onApprove={handleCloseModBarcode}
      >
        <div style={{ fontWeight: "bold", lineHeight: 2, margin: "1rem 0 0.75rem" }}>
          <div>ไม่พบรายการใบยา</div>
          <div>Order No. “{props.errorMessage?.[ACTION_BARCODE]?.barcode || ""}”</div>
        </div>
      </ModInfo>
    </>
  );
};

/* ------------------------------------------------------ */
/*                   SortOptionContent;                   */
/* ------------------------------------------------------ */
type SortOptionContentProps = {
  selectedSort: string;
  text: string;
  value: string;
};

const SortOptionContent = (props: SortOptionContentProps) => (
  <div style={{ display: "flex", margin: "0 -0.5rem" }}>
    <Icon
      color="blue"
      name="checkmark"
      style={props.selectedSort === props.value ? {} : { opacity: 0, pointerEvents: "none" }}
    />
    <span
      style={{ color: props.selectedSort === props.value ? "#2185d0" : "", marginLeft: "0.25rem" }}
    >
      {props.text}
    </span>
  </div>
);

const ForwardedCardDrugOrderQueue = forwardRef<any, any>(CardDrugOrderQueue);

export default React.memo(ForwardedCardDrugOrderQueue);

const CountdownTimer = (props: any) => {
  const initialTime = 120;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    setTimeRemaining(initialTime)
  }, [props.drugOrderQueue]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);
          props.runSequence?.({
            sequence: "SetDrugOrderQueue",
            action: "search",
            card: CARD_DRUG_ORDER_QUEUE,
            isRefresh: false,
          });
          return initialTime;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timeRemaining]);

  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  return (
    <div style={{ backgroundColor: "rgba(118, 207, 227, 0.25)", borderRadius: ".28571429rem" }}>
        <div style={{ color: "black", padding: "8px 20px", fontWeight: "bold"}}>{`Refresh : ${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</div>
    </div>
  );
};
